<template>
    <div class="cart">
        <h3>Organizational Structure</h3>
        <h6>组织机构</h6>
        <img src="@/assets/org.png"/>
    </div>
</template>
<script>
export default {
    name: 'organization'
}
</script>
<style scoped>
.cart {
    text-align: center;
}
h6 {
    margin-bottom: 18px;
}
</style>